import React, { lazy } from 'react';
import './App.module.scss';
import s from './App.module.scss';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import assortmentData from './config/assortment.json';
import specialtyData from './config/specialty.json';
import oilsBrandData from './config/brands/oilsBrands.json'
import batteriesBrandData from './config/brands/batteriesBrands.json'
import greaseBrandData from './config/brands/greaseBrands.json'
import fluidBrandData from './config/brands/fluidBrands.json'
import filtersBrandData from './config/brands/filtersBrands.json'
import cosmeticsBrandData from './config/brands/cosmeticsBeands.json'
import paintVarnishBrandData from './config/brands/paintVarnishBrands.json'
import oilBrandData from './config/brands/oilBrand.json'
import newsData from './config/brands/newsData.json'
import 'react-toastify/dist/ReactToastify.css';

import {
    assortmentRoutes,
    batteriesBrandsRoutes,
    cosmeticsBrandsRoutes,
    filtersBrandsRoutes,
    fluidBrandsRoutes,
    greaseBrandsRoutes,
    newsRoutes,
    oilsBrandsRoutes,
    paintVarnishBrandsRoutes,
    specialtyRoutes
} from "./config/mockRoutes";
import {ToastContainer} from "react-toastify";

const MainPage = lazy(() => import('./pages/main-page/MainPage'));
const AssortmentPage = lazy(() => import('./pages/assortment-page/AssortmentPage'));
const SpecialtyPage = lazy(() => import('./pages/specialty-page/SpecialtyPage'));
const BrandPage = lazy(() => import('./pages/brand-page/BrandPage'));

const App = () => {
    return (
        <div className={s.App}>
            <Router>
                <Routes>
                    <Route exact path="/" element={<MainPage/>}/>
                    {assortmentRoutes.map((item) => (
                        <Route
                            key={item}
                            exact
                            path={`/${item.path}`}
                            element={<AssortmentPage data={assortmentData[item.id]} customClass={item.customClass}/>}
                        />
                    ))}
                    {specialtyRoutes.map((item) => (
                        <Route
                            key={item}
                            exact
                            path={`/${item.path}`}
                            element={<SpecialtyPage data={specialtyData[item.id]}/>}
                        />
                    ))}
                    {oilsBrandsRoutes.map((item) => (
                        <Route
                            key={item}
                            exact
                            path={`/${item.path}`}
                            element={<BrandPage data={oilsBrandData[item.id]}/>}
                        />
                    ))}
                    <Route exact path="/oil" element={<BrandPage data={oilBrandData}/>}/>
                    {batteriesBrandsRoutes.map((item) => (
                        <Route
                            key={item}
                            exact
                            path={`/${item.path}`}
                            element={<BrandPage data={batteriesBrandData[item.id]}/>}
                        />
                    ))}
                    {greaseBrandsRoutes.map((item) => (
                        <Route
                            key={item}
                            exact
                            path={`/${item.path}`}
                            element={<BrandPage data={greaseBrandData[item.id]}/>}
                        />
                    ))}
                    {fluidBrandsRoutes.map((item) => (
                        <Route
                            key={item}
                            exact
                            path={`/${item.path}`}
                            element={<BrandPage data={fluidBrandData[item.id]}/>}
                        />
                    ))}
                    {filtersBrandsRoutes.map((item) => (
                        <Route
                            key={item}
                            exact
                            path={`/${item.path}`}
                            element={<BrandPage data={filtersBrandData[item.id]}/>}
                        />
                    ))}
                    {cosmeticsBrandsRoutes.map((item) => (
                        <Route
                            key={item}
                            exact
                            path={`/${item.path}`}
                            element={<BrandPage data={cosmeticsBrandData[item.id]}/>}
                        />
                    ))}
                    {paintVarnishBrandsRoutes.map((item) => (
                        <Route
                            key={item}
                            exact
                            path={`/${item.path}`}
                            element={<BrandPage data={paintVarnishBrandData[item.id]}/>}
                        />
                    ))}
                    {newsRoutes.map((item) => (
                        <Route
                            key={item}
                            exact
                            path={`/${item.path}`}
                            element={<BrandPage data={newsData[item.id]}/>}
                        />
                    ))}
                </Routes>
            </Router>

            <ToastContainer
                style={{
                    width: '400px',
                    maxWidth: 'fit-content',
                }}
            />
        </div>
    );
}

export default App;
