export const assortmentRoutes = [
    {
        id: 0,
        path: 'oils',
    },
    {
        id: 1,
        path: 'batteries',
        customClass: 'brands__batteries'
    },
    {
        id: 2,
        path: 'grease',
        customClass: 'brands__five'
    },
    {
        id: 3,
        path: 'fluid',
    },
    {
        id: 4,
        path: 'filters',
        customClass: 'brands__five'
    },
    {
        id: 5,
        path: 'cosmetics',
    },
    {
        id: 6,
        path: 'paint-varnish',
    }
]

export const specialtyRoutes = [
    {
        id: 0,
        path: 'truck',
    },
    {
        id: 1,
        path: 'auto',
    },
    {
        id: 2,
        path: 'tractor',
    },
    {
        id: 3,
        path: 'moto',
    },
    {
        id: 4,
        path: 'machinery',
    }
]

export const oilsBrandsRoutes = [
    {
        id: 0,
        path: 'oils/elf',
    },
    {
        id: 1,
        path: 'oils/addinol',
    },
    {
        id: 2,
        path: 'oils/total',
    },
    {
        id: 3,
        path: 'oils/mol',
    },
    {
        id: 4,
        path: 'oils/shell',
    },
    {
        id: 5,
        path: 'oils/liqui-moly',
    },
    {
        id: 6,
        path: 'oils/grom-ex',
    },
    {
        id: 7,
        path: 'oils/luxe',
    },
    {
        id: 8,
        path: 'oils/lotos',
    },
    {
        id: 9,
        path: 'oils/barrel',
    },
    {
        id: 10,
        path: 'oils/highway',
    },
    {
        id: 11,
        path: 'oils/mobil',
    },
    {
        id: 12,
        path: 'oils/castrol',
    },
    {
        id: 13,
        path: 'oils/aral',
    },
    {
        id: 14,
        path: 'oils/xado',
    },
    {
        id: 15,
        path: 'oils/motul',
    }
]

export const batteriesBrandsRoutes = [
    {
        id: 0,
        path: 'batteries/forse',
    },
    {
        id: 1,
        path: 'batteries/platin',
    },
    {
        id: 2,
        path: 'batteries/sz',
    }
]

export const greaseBrandsRoutes = [
    {
        id: 0,
        path: 'grease/grosse',
    },
    {
        id: 1,
        path: 'grease/oilright',
    },
    {
        id: 2,
        path: 'grease/arginol',
    },
    {
        id: 3,
        path: 'grease/lotos',
    },
    {
        id: 4,
        path: 'grease/mol',
    }
]

export const fluidBrandsRoutes = [
    {
        id: 0,
        path: 'fluid/grom-ex',
    },
    {
        id: 1,
        path: 'fluid/aliaska',
    },
    {
        id: 2,
        path: 'fluid/motor-lux',
    },
    {
        id: 3,
        path: 'fluid/luxe',
    },
    {
        id: 4,
        path: 'fluid/hepu',
    },
    {
        id: 5,
        path: 'fluid/hico',
    },
    {
        id: 6,
        path: 'fluid/highway',
    }
]

export const filtersBrandsRoutes = [
    {
        id: 0,
        path: 'filters/wix',
    },
    {
        id: 1,
        path: 'filters/zollex',
    },
    {
        id: 2,
        path: 'filters/mahle',
    },
    {
        id: 3,
        path: 'filters/mann',
    },
    {
        id: 4,
        path: 'filters/m-001',
    }
]

export const cosmeticsBrandsRoutes = [
    {
        id: 0,
        path: 'cosmetics/hi-gear',
    },
    {
        id: 1,
        path: 'cosmetics/er',
    },
    {
        id: 2,
        path: 'cosmetics/done-deal',
    },
    {
        id: 3,
        path: 'cosmetics/smt',
    },
    {
        id: 4,
        path: 'cosmetics/zollex',
    },
    {
        id: 5,
        path: 'cosmetics/liqui-moly',
    },
    {
        id: 6,
        path: 'cosmetics/k2',
    },
    {
        id: 7,
        path: 'cosmetics/xado',
    },
    {
        id: 8,
        path: 'cosmetics/piton',
    }
]

export const paintVarnishBrandsRoutes = [
    {
        id: 0,
        path: 'paint-varnish/newton',
    },
    {
        id: 1,
        path: 'paint-varnish/deco-blik',
    },
    {
        id: 2,
        path: 'paint-varnish/slider',
    },
    {
        id: 3,
        path: 'paint-varnish/inter',
    },
    {
        id: 4,
        path: 'paint-varnish/novol',
    },
    {
        id: 5,
        path: 'paint-varnish/ximpe',
    }
]

export const newsRoutes = [
    {
        id: 0,
        path: 'news/kamaoil',
    },
    {
        id: 1,
        path: 'news/aviksgroup',
    }
]
